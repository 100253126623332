import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout";
import styled from "styled-components";
import { ActionColours, TextColours } from "../utils/colours";
import Newslettersignup from "../components/Newslettersignup"

const BodyWrapper = styled.div`
  margin: 60px auto;
  max-width: 1450px;

  h1{
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 1;
  }
`;

const CrisisBox = styled.div`
display: flex;
flex-flow: column wrap;
margin-top: 25px;
`;

const CrisisTitle = styled.h3`
font-weight: 700;
margin-bottom: 8px;
color: ${ActionColours.red}
`;

const CrisisText = styled.div`
font-weight: 700;
`;

const CrisisButtons = styled.div`
display: flex;
justify-content: flex-start;
flex-flow: row wrap;
a{
  background-color: ${ActionColours.red};
  color: ${TextColours.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  margin: 0px 16px 16px 0;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  min-height: 47px;
  min-width: 200px;
  border: none;
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  transition: 0.3s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.5s ease-in-out;

  &:hover{
    font-weight: 900;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  }
}
`;


class PrivacyPolicy extends React.Component {
  render() {

    return (
      <main>
      <Layout location={this.props.location}>
      <SEO 
      title='Crisis'
      keywords={['anxious', 'extrovert', 'blog', 'breathing', 'mental', 'health', 'help', 'young', 'youngminds', 'student', 'mind', 'minds', 'exercises', 'services', 'blogs', 'calm', 'coping']}/>
      <BodyWrapper>
        <h1>Get the help you need</h1>
        <CrisisBox>
          <CrisisTitle>Urgent help: 999</CrisisTitle>
          <CrisisText>
          <p>If you are in an emergency and there is risk to life (that can be your own or another person's), call 999.</p>
          </CrisisText>
          <CrisisButtons>
            <a href="https://www.nhs.uk/nhs-services/urgent-and-emergency-care-services/when-to-call-999/?utm_source=Anxious-Extrovert-Ltd&utm_medium=website&utm_campaign=crisis-page" target="_blank" rel="noreferrer noopener" aria-label="When to call 999">When to call 999</a>
          </CrisisButtons>
        </CrisisBox>
        <CrisisBox>
        <CrisisTitle>Samaritans</CrisisTitle>
        <CrisisText>
        <p>Whatever you're going through, you can contact the Samaritans for support.</p>
        </CrisisText>
        <CrisisButtons>
          <a href="tel:116123">Phone: 116 123</a>
          <a href="mailto:jo@samaritans.org">Email: jo@samaritans.org</a>
        </CrisisButtons>
        </CrisisBox>
        <CrisisBox>
        <CrisisTitle>YoungMinds Crisis Messenger</CrisisTitle>
        <CrisisText>
        <p>Provides free, 24/7 text support for young people across the UK experiencing a mental health crisis.</p>
        <p>All texts are answered by trained volunteers, with support from experienced clinical supervisors.</p>
        <p>Texts are free from EE, O2, Vodafone, 3, Virgin Mobile, BT Mobile, GiffGaff, Tesco Mobile and Telecom Plus.</p>
        <p>Texts can be anonymous, but if the volunteer believes you are at immediate risk of harm, they may share your details with people who can provide support.</p>
        </CrisisText>
        <CrisisButtons>
          <a href="sms:85258">Text: YM to 85258</a>
        </CrisisButtons>
        </CrisisBox>
        <Newslettersignup/>
      </BodyWrapper>
      </Layout>
    </main>
    )
  }
}

export default PrivacyPolicy
